<template>
  <Perfil/> 
</template>          

<script>
import Perfil from "@/components/perfilUser/perfil.vue";

export default {
  components: {
    Perfil,
  },
};
</script>

<style>
</style>